import { getCountryCodeFromLocale, getLanguageCode, isNumeric } from 'app/functions/string';
import { mapAutocompleteResponse } from 'app/functions/api';

export type AutocompleteRequestArgs = {
  autocompleteUrl: string;
  query: string;
  locale: string;
  flixbusCitiesOnly: boolean;
  departureCity: string | number | null;
  partner: string;
  showStationSuggestions: boolean;
};

export const fetchAutocompleteResults = ({
  autocompleteUrl,
  query,
  locale,
  flixbusCitiesOnly,
  departureCity = null,
  partner = '',
  showStationSuggestions,
}: AutocompleteRequestArgs) => {
  const params = [
    `q=${query}`,
    `lang=${getLanguageCode(locale)}`,
    `country=${getCountryCodeFromLocale(locale)}`,
    `flixbus_cities_only=${flixbusCitiesOnly}`,
    `stations=${showStationSuggestions}`,
    `${showStationSuggestions ? 'popular_stations=true' : ''}`,
  ];

  if (partner) {
    params.push(`partner=${partner}`);
  }

  // only send if its a UUID
  if (departureCity && !isNumeric(departureCity)) {
    params.push(`departure_city=${departureCity}`);
  }

  return fetch(`${autocompleteUrl}?${params.join('&')}`)
    .then((response) => response.json())
    .then((jsonResponse) => mapAutocompleteResponse(jsonResponse));
};
