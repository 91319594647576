import React, { FC } from 'react';
import { connect } from 'react-redux';
import { serverSelector } from 'app/store/selectors';
import { StoreState } from 'app/models/types';

type DumbLazyComponentProps = {
  fallback: React.ReactElement;
  children: React.ReactNode;
  server: boolean;
};

const DumbLazyComponent: FC<DumbLazyComponentProps> = ({ fallback, children, server, ...rest }) => {
  if (server) {
    return fallback;
  }

  return (
    <React.Suspense fallback={fallback} {...rest}>
      {children}
    </React.Suspense>
  );
};

const mapStateToProps = (state: StoreState) => ({
  server: serverSelector(state),
});

export const LazyComponent = connect(mapStateToProps)(DumbLazyComponent);
