import React, { FC } from 'react';
import { getTestingAttributes } from 'app/functions';
import { elements } from 'app/tests/elements';
import styles from './ProductStepper.scss';
import { Quantity } from '@flixbus/honeycomb-react';
import { ProductsId } from '@webc/meeseeks-ui-sdk';
import { useTranslations } from 'app/hooks/useTranslations';
import { a11yLabelsKeys } from 'app/providers/a11yLabels';
import { ProductMessage } from 'app/components/product/ProductMessage';
import { useSelector } from 'react-redux';
import { otherPassengerProductsSelector } from 'app/store/selectors';
import { isHumanProduct } from 'app/functions/product/isHumanProduct';

export type ProductStepperProps = {
  productType: ProductsId;
  value: number;
  title: string;
  description: string;
  min?: number;
  max?: number;
  onChange: (value: number) => void;
  onProductMessagesSeen: (key: ProductsId, value: boolean) => void;
  productMessagesSeen: Record<string, boolean>;
  maxTotalPassengerCount?: number;
  passengerCount: number;
  tabTrapAction?: (e: React.KeyboardEvent) => void;
};

export const ProductStepper: FC<ProductStepperProps> = ({
  productType,
  value,
  title,
  description,
  min = 0,
  max = 0,
  onChange,
  productMessagesSeen,
  onProductMessagesSeen,
  maxTotalPassengerCount,
  passengerCount,
  tabTrapAction,
}) => {
  const { getTitleLabel, getDescriptionLabel, getAddLabel, getRemoveLabel } = useTranslations({
    getTitleLabel: title,
    getDescriptionLabel: description,
    getAddLabel: a11yLabelsKeys.products.productPlus,
    getRemoveLabel: a11yLabelsKeys.products.productMinus,
  });
  const otherPassengerProducts = useSelector(otherPassengerProductsSelector(productType));

  const getMaxAvailableCount = () => {
    if (maxTotalPassengerCount && isHumanProduct(productType)) {
      const sumOfOtherPassengersProducts = Object.keys(otherPassengerProducts).reduce(
        (acc, curr) => acc + otherPassengerProducts[curr].selectedAmount,
        0,
      );
      return maxTotalPassengerCount - sumOfOtherPassengersProducts;
    }

    return max;
  };

  const getMaxValue = () => {
    const availableNumber = getMaxAvailableCount();
    if (availableNumber >= max) {
      return max;
    }

    if (availableNumber <= 0) {
      return 0;
    }

    return availableNumber;
  };

  const checkIfMaxCountIsReached = () => {
    if (maxTotalPassengerCount && isHumanProduct(productType)) {
      const availableNumber = getMaxAvailableCount();
      if (availableNumber >= max) {
        return value === max;
      }
      return passengerCount >= maxTotalPassengerCount;
    }

    return value === max;
  };

  const onChangeHandler = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.currentTarget.value);
    const maxAvailableValue = getMaxValue();
    if (newValue > maxAvailableValue) {
      onChange(maxAvailableValue);
    } else {
      onChange(newValue);
    }
  };

  return (
    <div className={styles.productStepperWrapper}>
      <div className={styles.productStepper} data-product-type={productType}>
        <div className={styles.productStepperInfo}>
          <div className={styles.productStepperTitle}>{getTitleLabel()}</div>
          <div>{getDescriptionLabel()}</div>
        </div>

        <Quantity
          id={title}
          aria-label={getTitleLabel()}
          onChange={onChangeHandler}
          aria-valuenow={value}
          aria-valuetext={`${value}`}
          plusBtnProps={{
            ...getTestingAttributes(elements.PRODUCTS_PLUS_BUTTON),
            onClick: () => {
              onChange(value + 1);
            },
            'aria-label': `${getAddLabel()} ${getTitleLabel()}`,
            id: 'lastPlus',
            title: getAddLabel(),
            disabled: checkIfMaxCountIsReached() || value === getMaxValue(),
            onKeyDown: tabTrapAction,
          }}
          minusBtnProps={{
            ...getTestingAttributes(elements.PRODUCTS_MINUS_BUTTON),
            onClick: () => {
              onChange(value - 1);
            },
            'aria-label': `${getRemoveLabel()} ${getTitleLabel()}`,
            title: getRemoveLabel(),
            disabled: value === min,
          }}
          min={min}
          max={getMaxValue()}
          value={value}
          aria-live="off"
        />
      </div>

      <ProductMessage
        productType={productType}
        onProductMessagesSeen={onProductMessagesSeen}
        productMessagesSeen={productMessagesSeen}
      />
    </div>
  );
};
