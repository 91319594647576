import { StoreProductField } from 'app/models/types/store/product';
import { isHumanProduct } from 'app/functions/product/isHumanProduct';
import { ProductsId } from '@webc/meeseeks-ui-sdk';

export const getPassengerCount = (productTypes: Partial<StoreProductField['types']>) => {
  return Object.keys(productTypes).reduce((acc, curr) => {
    if (isHumanProduct(curr as ProductsId)) {
      return acc + productTypes[curr].selectedAmount;
    }
    return acc;
  }, 0);
};
