import cookies from 'js-cookie';
import { redirectToUrl, replaceUrl } from 'app/functions/environment';
import {
  getUrlParamsFromSearchPayload,
  isGoogleAnalyticsAvailable,
  ParamsSearchPayload,
  webtrekkValuesAvailable,
} from 'app/functions/general';
import { getCallbacksResult } from 'app/storage/parameterCallbacks';
import { SearchMaskMountOptions } from '@webc/meeseeks-ui-sdk';
import { shopUrls } from 'app/config/shopUrls';
import { getQueryString } from 'app/functions/environment';

/**
 * Redirects to a search result page adding a querystring with
 * a parameters based on the provided payload and options to the URL
 *
 * @param {Object} payload
 * @param {Object} options
 */
export const redirectToSearchResultsPage = (
  payload: ParamsSearchPayload,
  options: SearchMaskMountOptions,
  features: {
    [key: string]: boolean;
  },
) => {
  // only if its enabled
  if (options.handleOwnRequestSearchEvent !== true) return;
  const host = options.searchResultsHost || shopUrls[payload.locale] || window.location.hostname;
  const urlParams = getUrlParamsFromSearchPayload(payload);

  // FlixTrain flag
  if (options.flixTrain) {
    urlParams.set('origin_flix_train', '1');
  }

  // BackRide flag
  if (urlParams.get('backRideDate')) {
    urlParams.set('backRide', '1');
  }

  // pass feature flags along
  if (Object.keys(features).length > 0) {
    Object.keys(features).forEach((featureName) => {
      urlParams.set(`features[${featureName}]`, features[featureName] ? '1' : '0');
    });
  }

  // pass search host params
  if (options.searchResultsHostParams && options.searchResultsHostParams.length > 0) {
    options.searchResultsHostParams.forEach((param: any) => {
      if (param[0] && param[1]) {
        urlParams.set(param[0], param[1]);
      }
    });
  }

  // Deeplink tracking
  const defaultUrlParams = new URLSearchParams(getQueryString());
  const utm_source = defaultUrlParams.get('utm_source');
  const utm_medium = defaultUrlParams.get('utm_medium');
  const utm_campaign = defaultUrlParams.get('utm_campaign');
  const utm_term = defaultUrlParams.get('utm_term');
  const utm_content = defaultUrlParams.get('utm_content');
  const gad_source = defaultUrlParams.get('gad_source');
  const gclid = defaultUrlParams.get('gclid');

  if (utm_source) {
    urlParams.set('utm_source', utm_source);
  }

  if (utm_medium) {
    urlParams.set('utm_medium', utm_medium);
  }

  if (utm_campaign) {
    urlParams.set('utm_campaign', utm_campaign);
  }

  if (utm_term) {
    urlParams.set('utm_term', utm_term);
  }

  if (utm_content) {
    urlParams.set('utm_content', utm_content);
  }

  if (gad_source) {
    urlParams.set('gad_source', gad_source);
  }

  if (gclid) {
    urlParams.set('gclid', gclid);
  }

  // Webtrekk parameters
  if (webtrekkValuesAvailable()) {
    urlParams.set('wt_eid', window.wt.eid);
    urlParams.set('wt_t', Date.now().toString());

    // affiliate code
    const affiliateCookie = cookies.get('affiliate');
    if (affiliateCookie) {
      urlParams.set('affiliate', affiliateCookie);
    }

    // google analytics clientId
    if (isGoogleAnalyticsAvailable()) {
      // GA returns a string value to be concatenated directly to the url, like:
      // _ga=2.101072776.821422058.1523018101-1425581033.1523018101
      const gaQueryString = window.ga.getByName('gaGlobal').get('linkerParam');

      // Need to parse the google query string and extract only the value
      const gaUrlParams = new URLSearchParams(gaQueryString);
      urlParams.set('_ga', gaUrlParams.get('_ga') || '');
    }
  }

  const extraParameters = getCallbacksResult(payload, options);

  Object.keys(extraParameters).forEach((parameterKey) =>
    urlParams.set(parameterKey, extraParameters[parameterKey]),
  );

  const path = 'search';
  const urlToRedirectTo = `${window.location.protocol}//${host}/${path}?${urlParams.toString()}`;

  if (!options.onePageSearch) {
    redirectToUrl(urlToRedirectTo);
  } else {
    replaceUrl(
      `${window.location.protocol}//${host}${window.location.pathname}?${urlParams.toString()}`,
    );
  }
};
