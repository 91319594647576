import { SearchMaskMountOptions } from '@webc/meeseeks-ui-sdk';
import { getQueryString, getSearchMaskValuesFromQueryString } from 'app/functions';

import { StoreTranslationsDateField } from 'app/models/types/store/translations';
import { fetchDatesTranslations, fetchTranslations } from 'app/providers';
import {
  loadUi,
  requestTranslationsSucceeded,
  UPDATE_OPTIONS,
  UpdateOptionsAction,
} from 'app/store/actions';
import { optionsSelector } from 'app/store/selectors';
import { takeLatest, select, put, call, all } from 'redux-saga/effects';
import { updateSearchMaskValues } from 'app/store/sagas/general/updateSearchMaskValues';

export function* requestTranslations(locale: string) {
  try {
    const [translationsResponse, translationsDatesResponse]: [
      { [key: string]: string },
      StoreTranslationsDateField,
    ] = yield all([call(fetchTranslations, locale), call(fetchDatesTranslations, locale)]);

    yield put(requestTranslationsSucceeded(translationsResponse, translationsDatesResponse));
  } catch (error) {
    // @todo: handle error
  }
}

export function* onUpdateOptionsSagaWorker(action: UpdateOptionsAction) {
  const oldOptions: ReturnType<typeof optionsSelector> = yield select(optionsSelector);
  const newOptions: SearchMaskMountOptions = { ...oldOptions, ...action.options };
  const locale = newOptions.locale;
  const searchMaskValues = {
    ...action.options?.initialSearchMaskValues,
    ...getSearchMaskValuesFromQueryString(getQueryString()),
  } as SearchMaskMountOptions['initialSearchMaskValues'];

  yield call(updateSearchMaskValues, searchMaskValues, true, oldOptions);

  yield all([requestTranslations(locale)]);

  yield put(loadUi());
}

export function* onUpdateOptions() {
  yield takeLatest<UpdateOptionsAction>(UPDATE_OPTIONS, onUpdateOptionsSagaWorker);
}
