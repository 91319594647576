import { takeEvery, select, put } from 'redux-saga/effects';
import { REQUEST_SEARCH, RequestSearchAction, storeRecentCity } from 'app/store/actions';
import { isSearchInvalid, redirectToSearchResultsPage } from 'app/functions/general';
import {
  arrivalCitySelector,
  departureCitySelector,
  departureDateTimestampSelector,
  featuresSelector,
  localeSelector,
  optionsSelector,
  passengerCountSelector,
  selectedProductsSelector,
  selectedReturnDateSelector,
  selectedRouteNameSelector,
  wheelchairSelectedSelector,
} from 'app/store/selectors';
import { emitRequestSearchEvent } from 'app/events';
import { SearchMaskMountOptions } from '@webc/meeseeks-ui-sdk';
import { CityDetailsResult, PartialSearchMaskLocation } from 'app/models/types/store/location';
import { RequestSearchPayload } from 'app/models/types/store/general';
import { mapLocationToAutocomplete } from 'app/functions';
import { emitSearchForStationEvent } from 'app/events/emitSearchForStationEvent';

const DIRECTION_TO = 'to';
const DIRECTION_FROM = 'from';

export function* handleRequestSearch({ noRedirect }: RequestSearchAction) {
  const options: SearchMaskMountOptions = yield select(optionsSelector);
  const features: { [key: string]: boolean } = yield select(featuresSelector);

  const departureCity: CityDetailsResult | PartialSearchMaskLocation =
    yield select(departureCitySelector);
  const arrivalCity: CityDetailsResult | PartialSearchMaskLocation =
    yield select(arrivalCitySelector);
  const routeName: string = yield select(selectedRouteNameSelector);
  const departureDate: number = yield select(departureDateTimestampSelector);
  const returnDate: number | null = yield select(selectedReturnDateSelector);
  const selectedProducts: Record<string, any> = yield select(selectedProductsSelector);
  const locale: string = yield select(localeSelector);
  const passengerCount: number = yield select(passengerCountSelector);
  const wheelchairsCount: number = yield select(wheelchairSelectedSelector);

  if (
    isSearchInvalid({
      passengerCount,
      departureDate,
      wheelchairsCount,
      departureCity: departureCity as CityDetailsResult,
      arrivalCity: arrivalCity as CityDetailsResult,
    })
  ) {
    return;
  }

  yield put(
    storeRecentCity(DIRECTION_FROM, mapLocationToAutocomplete(departureCity as CityDetailsResult)),
  );
  yield put(
    storeRecentCity(DIRECTION_TO, mapLocationToAutocomplete(arrivalCity as CityDetailsResult)),
  );

  const payload: RequestSearchPayload = {
    departureCity: departureCity.uuid || '',
    arrivalCity: arrivalCity.uuid || '',
    routeName,
    departureDate,
    returnDate,
    products: selectedProducts,
    locale,
    arrivalStop: arrivalCity.stationId,
    departureStop: departureCity.stationId,
    arrivalStopName: arrivalCity.stationName,
    departureStopName: departureCity.stationName,
  };

  const normalizedPayload = {
    ...payload,
    products: Object.values(payload.products).reduce((acc, curr) => {
      acc[curr.id] = curr.selectedAmount;
      return acc;
    }, {}),
    departureStation: null, // stations are not longer used, but cant break external API yet
    arrivalStation: null,
  };

  if (!!arrivalCity.stationId || !!departureCity.stationId) {
    emitSearchForStationEvent();
  }
  emitRequestSearchEvent(normalizedPayload);
  if (noRedirect) {
    return;
  }
  redirectToSearchResultsPage(normalizedPayload, options, features);
}

export function* requestSearchWatcher() {
  yield takeEvery(REQUEST_SEARCH, handleRequestSearch);
}
