import { emitFieldClosedEvent } from 'app/events';

export const closeElement = (element: string, options?: { [key: string]: any }) => {
  setTimeout(() => {
    const active = document.activeElement;
    if (
      !document.getElementById('search-mask-component')?.contains(active) ||
      !active?.hasAttribute('aria-expanded') ||
      (active?.hasAttribute('aria-expanded') && active?.getAttribute('aria-expanded') === 'false')
    ) {
      emitFieldClosedEvent(element, options);
    }
  }, 100);
};
